<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-alert variant="info" show>{{ $t('ects_values_info') }}</b-alert>
                        </b-col>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template v-for="(item, index) in tableFields" v-slot:[`head(${item.key})`]="data">
                                    <span v-if="index != 0" class="cursor-pointer" v-b-tooltip.hover :title=item.label>{{ item.key }}</span>
                                    <span v-else>{{ item.label }}</span>
                                </template>
                                <template v-for="(item, index) in tableData[0]" v-slot:[`cell(${item.rank})`]="data">
                                    <multi-selectbox
                                        :options="statusOptions"
                                        :multiple="false"
                                        @input="saveData(data)"
                                        v-if="data.item[index]"
                                        :id="'b-input-'+(index)+'-'+data.item[index].id"
                                        v-model="data.item[index].value" />
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
import EctsService from "@/services/EctsService"
import ProgramOutcome from "@/services/ProgramOutcome"

// Pages

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        MultiSelectbox
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            statusOptions: [
                {
                    value: "0",
                    text: "-"
                },
                {
                    value: "1",
                    text: "1"
                },
                {
                    value: "2",
                    text: "2"
                },
                {
                    value: "3",
                    text: "3"
                },
                {
                    value: "4",
                    text: "4"
                },
                {
                    value: "5",
                    text: "5"
                }
            ],
            tableData: [],
            tableFields: [
                {
                    key: 'info',
                    label: this.$t('ects_course_program_outcomes')
                }
            ],
        };
    },
    created() {
        this.formData = this.program
        this.getData()
    },
    methods: {
        getData(){
            const config = {
                params: {
                    filter: {
                        program_code: this.program.code
                    },
                    sort: 'id',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ProgramOutcome.getAll(config).then(response => {
                //console.log(response)
                response.data.data.forEach(item => {
                    this.tableFields.push({
                        key: item.rank.toString(),
                        label: this.getLocaleField(item.program_outcome_definition_name),
                        program_outcome_id: item.id,
                        rank: item.rank
                    })
                })
            }).finally(() => {
                this.getCoursesData()
            }).catch(e => {
                this.showErrors(e)
            })
        },

        getCoursesData(){
            this.tableData = []
            EctsService.getProgramCoordinatorCourses().then(response => {
                response.data.data.forEach(itm => {
                    let objData = {}
                    this.tableFields.forEach((item, i) => {
                        if(i > 0){
                            objData[i] = {
                                id: itm.id,
                                program_outcome_id: item.program_outcome_id,
                                value: "0",
                                rank: item.rank
                            }
                        }
                    })
                    this.tableData.push({
                        info: itm.code+' '+this.getLocaleText(itm, 'name'),
                        ...objData
                    })
                })
            }).finally(() => {
                this.getComputeData()
            }).catch(e => {
                this.showErrors(e)
            })
        },

        getComputeData(){
            EctsService.getProgramCoordinatorCourseProgramOutcomes().then(response => {
                response.data.data.forEach(itm => {
                    this.tableData.forEach((item,index) => {
                        Object.keys(item).forEach((key) => {
                            if(typeof item[key] == "object" && item[key].program_outcome_id == itm.program_outcome_id && item[key].id == itm.course_id){
                                this.tableData[index][key].value = itm.value
                                this.tableData[index][key].course_program_outcome_id = itm.id
                            }
                        })
                    })
                })
            }).catch(e => {
                this.showErrors(e)
            })
        },

        saveData(data) {
            if(data.value.hasOwnProperty('course_program_outcome_id') && data.value.value == "0"){
                EctsService.deleteProgramCoordinatorCourseProgramOutcomes(data.value.course_program_outcome_id).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                }).catch(e => {
                    this.showErrors(e, null)
                })
            } else if(data.value.hasOwnProperty('course_program_outcome_id') && data.value.value != "0"){
                let formData = {
                    course_id: data.value.id,
                    program_outcome_id: data.value.program_outcome_id,
                    value: data.value.value
                }
                EctsService.updateProgramCoordinatorCourseProgramOutcomes(data.value.course_program_outcome_id,formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                }).catch(e => {
                    this.showErrors(e, null)
                })
            } else {
                let formData = {
                    course_id: data.value.id,
                    program_outcome_id: data.value.program_outcome_id,
                    value: data.value.value
                }
                EctsService.storeProgramCoordinatorCourseProgramOutcomes(formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                }).catch(e => {
                    this.showErrors(e, null)
                })
            }
        }

    },
}
</script>
