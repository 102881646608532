<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_name')">
                                    {{ formData.courseName }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="rank" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('rank')">
                                        <b-form-input v-model="formData.rank"
                                                      :class="errors[0] ? 'is-invalid':''"
                                                      type="number" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="status" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('status')">
                                        <multi-selectbox
                                            v-model="formData.status"
                                            :multiple="false"
                                            :options="statusOptions"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="tyyc_basic_field_type" rules="required" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_tyyc_basic_field_type')">
                                        <parameter-selectbox
                                            v-model="formData.tyyc_basic_field_type"
                                            code="tyyc_basic_field_types"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="name" rules="required" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_course_outcome')">
                                        <b-form-textarea v-model="formData.name"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="name_en" rules="required" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_course_outcome')+' (EN)'">
                                        <b-form-textarea v-model="formData.name_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button
                    variant="primary"
                    @click="save"
                    :disabled="formLoading">
                    {{ $t("save") }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
import EctsService from "@/services/EctsService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        ParameterSelectbox,
        MultiSelectbox
    },
    props: {
        course: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            statusOptions: [
                {
                    value: "a",
                    text: this.$t('active')
                },
                {
                    value: "p",
                    text: this.$t('passive')
                }
            ],
        };
    },
    created() {
        this.formData = this.course
        if(this.formData.actionType == 'new'){
            this.formData.courseName = this.getLocaleText(this.formData, 'name')
            this.formData.rank = null
            this.formData.status = null
            this.formData.tyyc_basic_field_type = null
            this.formData.name = null
            this.formData.name_en = null
        } else {
            this.formData.courseName = this.formData.course_name
        }
    },
    methods: {
        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                if(this.formData.actionType == 'new'){
                    this.formData.course_id = this.formData.id
                    EctsService.storeProgramCoordinatorCourseOutcomes(this.formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$emit('closeComponent', {
                            data: this.course,
                            component: 'course-outcomes',
                        })
                        this.formLoading = false
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate)
                        this.formLoading = false
                    })
                } else {
                    EctsService.updateProgramCoordinatorCourseOutcomes(this.formData.id, this.formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.course.id = this.course.course_id
                        this.$emit('closeComponent', {
                            data: this.course,
                            component: 'course-outcomes',
                        })
                        this.formLoading = false
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate)
                        this.formLoading = false
                    })
                }

            }
        }
    },
}
</script>
